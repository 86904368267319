import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["startButton", "noteDisplay", "tunerRing", "indicator", "freqDisplay"]
    static values = {
        compressorEnabled: Boolean
    }


    bufferSize = 4096
    recentFrequencies = []
    maxRecentReadings = 8

    // Compressor settings
    ENABLE_COMPRESSOR = true  // Global toggle for compressor feature
    COMPRESSOR_SETTINGS = {
        threshold: -50,      // dB - when to start compressing
        knee: 30,           // dB - how gradually to apply compression
        ratio: 12,          // compression ratio
        attack: 0.003,      // seconds - how fast to apply compression
        release: 0.5,      // seconds - how fast to release compression
        maxGain: 20         // dB - maximum gain to apply during decay
    }


    // Add threshold for low E detection
    LOW_E_THRESHOLD = 90  // Hz - just above E2 (82.41 Hz)

    // Constants for tuning visualization
    CENTS_RANGE = 50
    MAX_ROTATION = 180

    // Standard guitar tunings
    TUNINGS = {
        'standard': ['E2', 'A2', 'D3', 'G3', 'B3', 'E4'],
        'dropD': ['D2', 'A2', 'D3', 'G3', 'B3', 'E4'],
        'openG': ['D2', 'G2', 'D3', 'G3', 'B3', 'D4']
    }

    // Define frequency ranges for guitar notes with a bias towards fundamentals
    GUITAR_NOTES = [
        { note: 'D2', freq: 73.42, min: 71.0, max: 77.8, fundamental: true },
        { note: 'E2', freq: 82.41, min: 77.8, max: 84.9, fundamental: true },
        { note: 'F2', freq: 87.31, min: 84.9, max: 89.9, fundamental: true },
        { note: 'F#2', freq: 92.50, min: 89.9, max: 95.25, fundamental: true },
        { note: 'G2', freq: 98.00, min: 95.25, max: 101.0, fundamental: true },
        { note: 'G#2', freq: 103.83, min: 101.0, max: 107.0, fundamental: true },
        { note: 'A2', freq: 110.00, min: 107.0, max: 113.0, fundamental: true },
        { note: 'A#2', freq: 116.54, min: 113.0, max: 120.0, fundamental: true },
        { note: 'B2', freq: 123.47, min: 120.0, max: 127.0, fundamental: true },
        { note: 'C3', freq: 130.81, min: 127.0, max: 134.5, fundamental: true },
        { note: 'C#3', freq: 138.59, min: 134.5, max: 142.5, fundamental: true },
        { note: 'D3', freq: 146.83, min: 142.5, max: 151.0, fundamental: true },
        { note: 'D#3', freq: 155.56, min: 151.0, max: 160.0, fundamental: true },
        { note: 'E3', freq: 164.81, min: 160.0, max: 169.5, fundamental: true },
        { note: 'F3', freq: 174.61, min: 169.5, max: 179.5, fundamental: true },
        { note: 'F#3', freq: 185.00, min: 179.5, max: 190.5, fundamental: true },
        { note: 'G3', freq: 196.00, min: 190.5, max: 201.5, fundamental: true },
        { note: 'G#3', freq: 207.65, min: 201.5, max: 213.5, fundamental: true },
        { note: 'A3', freq: 220.00, min: 213.5, max: 226.5, fundamental: true },
        { note: 'A#3', freq: 233.08, min: 226.5, max: 240.0, fundamental: true },
        { note: 'B3', freq: 246.94, min: 240.0, max: 254.0, fundamental: true },
        { note: 'C4', freq: 261.63, min: 254.0, max: 269.0, fundamental: true },
        { note: 'C#4', freq: 277.18, min: 269.0, max: 285.0, fundamental: true },
        { note: 'D4', freq: 293.66, min: 285.0, max: 302.0, fundamental: true },
        { note: 'D#4', freq: 311.13, min: 302.0, max: 320.0, fundamental: true },
        { note: 'E4', freq: 329.63, min: 320.0, max: 339.0, fundamental: true }
    ]

    connect() {
        this.initializePitchDetector()
        this.selectedTuning = 'standard'
        this.lastFrequency = null
        this.noteDisplayTarget.textContent = "Ag"
        this.compressorEnabled = this.ENABLE_COMPRESSOR
    }

    async initializePitchDetector() {
        try {
            const wasmModule = await import('/javascripts/tuner/pitch_detection_wasm.js')
            await wasmModule.default({
                module_or_path: '/javascripts/tuner/pitch_detection_wasm_bg.wasm'
            })

            const { McLeodDetector } = wasmModule
            this.detector = McLeodDetector.new(this.bufferSize, 4096)

            await this.initializeAudioWorklet()

            this.startButtonTarget.disabled = false
            this.tunerRingTarget.classList.remove('opacity-50')
        } catch (error) {
            console.error("Failed to initialize pitch detector:", error)
            this.startButtonTarget.disabled = true
        }
    }

    async initializeAudioWorklet() {
        if (this.audioContext) {
            await this.audioContext.audioWorklet.addModule('/javascripts/tuner/audio_tuner_processor.js')
        }
    }

    toggleTuner() {
        if (this.audioContext) {
            this.stopTuner()
        } else {
            this.startTuner()
        }
    }

    async startTuner() {
        try {
            this.audioContext = new AudioContext({
                sampleRate: 48000,
                latencyHint: 'interactive'
            });

            await this.initializeAudioWorklet();

            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    noiseSuppression: false,
                    echoCancellation: false,
                    autoGainControl: false,
                    channelCount: 1,
                    sampleRate: 48000
                }
            });

            // Create and configure compressor node
            this.compressor = this.audioContext.createDynamicsCompressor();
            // Correctly set AudioParam values
            this.compressor.threshold.value = this.COMPRESSOR_SETTINGS.threshold;
            this.compressor.knee.value = this.COMPRESSOR_SETTINGS.knee;
            this.compressor.ratio.value = this.COMPRESSOR_SETTINGS.ratio;
            this.compressor.attack.value = this.COMPRESSOR_SETTINGS.attack;
            this.compressor.release.value = this.COMPRESSOR_SETTINGS.release;

            // Create gain node for additional makeup gain
            this.makeupGain = this.audioContext.createGain();
            this.makeupGain.gain.value = 1.0;  // Initial gain value

            // Enhanced audio chain with compressor
            const source = this.audioContext.createMediaStreamSource(stream);
            this.workletNode = new AudioWorkletNode(this.audioContext, 'audio-tuner-processor');

            // Set up audio routing based on compressor state
            if (this.compressorEnabled) {
                source
                    .connect(this.compressor)
                    .connect(this.makeupGain)
                    .connect(this.workletNode);
            } else {
                source.connect(this.workletNode);
            }

            this.workletNode.connect(this.audioContext.destination);

            // Add envelope follower for decay detection
            this.lastPeakTime = this.audioContext.currentTime;
            this.lastPeakValue = 0;

            this.workletNode.port.onmessage = (event) => {
                if (event.data.type === 'buffer') {
                    this.processAudioBuffer(event.data.buffer);
                    if (this.compressorEnabled) {
                        this.updateDecayCompensation(event.data.buffer);
                    }
                }
            };

            this.startButtonTarget.textContent = "Stop";
            this.tunerRingTarget.classList.add('active');

        } catch (error) {
            console.error("Error accessing microphone:", error);
            this.audioContext = null;
        }
    }

    updateDecayCompensation(buffer) {
        // Calculate RMS value of the buffer
        const rms = Math.sqrt(buffer.reduce((sum, sample) => sum + sample * sample, 0) / buffer.length);
        const now = this.audioContext.currentTime;

        // Detect new note onset
        if (rms > this.lastPeakValue * 1.5) {
            this.lastPeakTime = now;
            this.lastPeakValue = rms;
            this.makeupGain.gain.setValueAtTime(1.0, now);
        } else if (rms < this.lastPeakValue * 0.8) {
            // Note is decaying
            const timeSincePeak = now - this.lastPeakTime;
            const decayCompensation = Math.min(
                this.COMPRESSOR_SETTINGS.maxGain,
                1.0 + (timeSincePeak * 2)  // Gradually increase gain
            );

            // Apply smooth gain increase
            this.makeupGain.gain.linearRampToValueAtTime(
                decayCompensation,
                now + 0.1
            );
        }

        // Update peak value with decay
        this.lastPeakValue = Math.max(rms, this.lastPeakValue * 0.95);
    }

    toggleCompressor() {
        this.compressorEnabled = !this.compressorEnabled;

        // Reconnect audio chain with new routing
        if (this.audioContext && this.workletNode) {
            const source = this.workletNode.context.createMediaStreamSource(
                this.audioContext.destination.stream
            );

            // Disconnect existing connections
            this.workletNode.disconnect();
            if (this.compressor) {
                this.compressor.disconnect();
                this.makeupGain.disconnect();
            }

            // Reconnect with new routing
            if (this.compressorEnabled) {
                source
                    .connect(this.compressor)
                    .connect(this.makeupGain)
                    .connect(this.workletNode);
            } else {
                source.connect(this.workletNode);
            }

            this.workletNode.connect(this.audioContext.destination);
        }
    }

    processAudioBuffer(buffer) {
        if (!this.detector) {
            this.noteDisplayTarget.textContent = "Ag";
            return;
        }

        const pitch = new Float32Array(2);

        this.detector.get_pitch(
            buffer,
            this.audioContext.sampleRate,
            0.1,
            0.7,
            pitch
        );

        if (pitch[0] !== -1 && pitch[1] > 0.75) {
            let rawFrequency = pitch[0];

            // Handle octave detection for low register notes
            // Check frequencies up to A3 (220Hz) for potential octave adjustment
            if (rawFrequency > 145 && rawFrequency < 175) {
                // Only apply octave adjustment if the halved frequency would fall
                // within our valid note ranges
                const halvedFreq = rawFrequency / 2;
                const potentialLowNote = this.GUITAR_NOTES.find(
                    note => halvedFreq >= note.min && halvedFreq <= note.max
                );
                if (potentialLowNote) {
                    rawFrequency = halvedFreq;
                }
            }

            this.recentFrequencies.push(rawFrequency);
            if (this.recentFrequencies.length > this.maxRecentReadings) {
                this.recentFrequencies.shift();
            }

            if (this.recentFrequencies.length >= 5) {
                const stableFrequency = this.getStableFrequency();

                if (stableFrequency !== null) {
                    this.freqDisplayTarget.textContent = `${stableFrequency.toFixed(1)} Hz`;
                    const noteInfo = this.getNoteInfo(stableFrequency);

                    if (noteInfo) {
                        // Always show the note, but still indicate if it's in the current tuning
                        this.updateDisplay(noteInfo, stableFrequency);
                    }
                }
            }
        } else {
            this.recentFrequencies = [];
            this.freqDisplayTarget.textContent = "- Hz";
        }
    }

    getStableFrequency(currentFrequency) {
        if (this.recentFrequencies.length < 5) return null;

        // Get median to remove outliers
        const sorted = [...this.recentFrequencies].sort((a, b) => a - b);
        const median = sorted[Math.floor(sorted.length / 2)];

        // More tolerant stability check for low frequencies
        let tolerance = median < this.LOW_E_THRESHOLD ? 4 : 3;

        // Check if readings are stable enough
        const meanDiff = this.recentFrequencies.reduce((sum, freq) =>
            sum + Math.abs(freq - median), 0) / this.recentFrequencies.length;

        if (meanDiff > tolerance) {
            return null;
        }

        return median;
    }

    isConsistentNote(noteInfo) {
        if (!this.lastValidNote) {
            this.lastValidNote = noteInfo;
            return true;
        }

        // Check if we're getting the same note consistently
        if (noteInfo.name === this.lastValidNote.name) {
            this.lastValidNote = noteInfo;
            return true;
        }

        // Only change notes if we've gotten a different note multiple times
        const differentNoteCount = this.recentFrequencies
            .map(freq => this.getNoteInfo(freq))
            .filter(info => info && info.name === noteInfo.name)
            .length;

        const isConsistent = differentNoteCount >= 4;  // Need at least 4 readings of the new note

        if (isConsistent) {
            this.lastValidNote = noteInfo;
        }

        return isConsistent;
    }


    stopTuner() {
        if (this.compressor) {
            this.compressor.disconnect();
        }
        if (this.makeupGain) {
            this.makeupGain.disconnect();
        }

        if (this.workletNode) {
            this.workletNode.disconnect();
        }
        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }

        clearTimeout(this.displayTimeout);

        this.startButtonTarget.textContent = "Start";
        this.tunerRingTarget.classList.remove('active', 'in-tune');
        this.noteDisplayTarget.textContent = "Ag";
        this.freqDisplayTarget.textContent = "- Hz";
        this.resetIndicator();
        this.recentFrequencies = [];
    }

    findClosestNote(frequency) {
        let closestNote = this.GUITAR_NOTES[0];
        let smallestError = Math.abs(frequency - this.GUITAR_NOTES[0].freq);

        this.GUITAR_NOTES.forEach(note => {
            const error = Math.abs(frequency - note.freq);
            if (error < smallestError) {
                smallestError = error;
                closestNote = note;
            }
        });

        return closestNote;
    }

    getMedianFrequency() {
        if (this.recentFrequencies.length === 0) return null;

        const sorted = [...this.recentFrequencies].sort((a, b) => a - b);
        const mid = Math.floor(sorted.length / 2);

        return sorted.length % 2 === 0
            ? (sorted[mid - 1] + sorted[mid]) / 2
            : sorted[mid];
    }

    isStableReading() {
        if (this.recentFrequencies.length < 3) return false;

        const mean = this.recentFrequencies.reduce((a, b) => a + b) / this.recentFrequencies.length;
        const variance = this.recentFrequencies.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / this.recentFrequencies.length;
        const stdDev = Math.sqrt(variance);

        return stdDev < 2;
    }

    calculateCentsOff(frequency, targetFrequency) {
        return Math.round(1200 * Math.log2(frequency / targetFrequency));
    }

    getNoteInfo(frequency) {
        const noteInfo = this.GUITAR_NOTES.find(
            note => frequency >= note.min && frequency <= note.max
        );

        if (!noteInfo) return null;

        const centsOff = this.calculateCentsOff(frequency, noteInfo.freq);
        // Still check full note with octave for tuning match
        const isInTuning = this.TUNINGS[this.selectedTuning].includes(noteInfo.note);

        // Remove octave number from display name but keep original note for tuning check
        const displayName = noteInfo.note.replace(/\d+/, '');

        return {
            name: displayName,  // Will show 'E' instead of 'E2', 'A' instead of 'A2', etc.
            targetFreq: noteInfo.freq,
            centsOff: centsOff,
            isInTuning: isInTuning  // Still track this for visual feedback
        };
    }

    updateDisplay(noteInfo, frequency) {
        this.noteDisplayTarget.textContent = noteInfo.name;
        // Still show visual feedback for notes in the current tuning
        this.noteDisplayTarget.classList.toggle('in-tuning', noteInfo.isInTuning);

        const rotation = (noteInfo.centsOff / this.CENTS_RANGE) * this.MAX_ROTATION;
        const clampedRotation = Math.max(-this.MAX_ROTATION, Math.min(this.MAX_ROTATION, rotation));

        this.indicatorTarget.style.transform =
            `rotate(${clampedRotation}deg) translateY(-58px)`;

        if (Math.abs(noteInfo.centsOff) < 5) {
            this.tunerRingTarget.classList.add('in-tune');
        } else {
            this.tunerRingTarget.classList.remove('in-tune');
        }
    }


    resetIndicator() {
        this.indicatorTarget.style.transform = 'rotate(0deg) translateY(-58px)';
    }

    changeTuning(tuningName) {
        if (this.TUNINGS[tuningName]) {
            this.selectedTuning = tuningName;
            console.log(`Changed to ${tuningName} tuning: ${this.TUNINGS[tuningName].join(', ')}`);
        }
    }

    disconnect() {
        this.stopTuner();
        if (this.detector) {
            this.detector.free();
        }
    }
}