// app/javascript/controllers/tuner_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["output"]
    bufferSize = 2048

    connect() {
        this.initializeWasm()
    }

    async initializeWasm() {
        try {
            const wasmModule = await import('/javascripts/tuner/pitch_detection_wasm.js')
            await wasmModule.default({
                module_or_path: '/javascripts/tuner/pitch_detection_wasm_bg.wasm'
            })

            const { McLeodDetector } = wasmModule
            // Initialize with size and padding
            this.detector = McLeodDetector.new(this.bufferSize, 2048)
            console.log("WASM initialized successfully")
            await this.initAudio()
        } catch (error) {
            console.error("Failed to initialize WASM:", error)
        }
    }

    async initAudio() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
            const audioContext = new AudioContext()
            const source = audioContext.createMediaStreamSource(stream)
            const processor = audioContext.createScriptProcessor(this.bufferSize, 1, 1)

            processor.onaudioprocess = (e) => {
                const inputData = e.inputBuffer.getChannelData(0)
                this.detectPitch(inputData)
            }

            source.connect(processor)
            processor.connect(audioContext.destination)

            this.audioContext = audioContext
            this.processor = processor
            this.source = source
        } catch (error) {
            console.error("Error accessing microphone:", error)
        }
    }

    detectPitch(audioData) {
        if (!this.detector) return

        const sampleRate = this.audioContext.sampleRate
        const pitch = new Float32Array(2)

        this.detector.get_pitch(
            audioData,
            sampleRate,
            0.2,  // power threshold
            0.7,  // clarity threshold
            pitch
        )

        if (pitch[0] !== -1) {
            const frequency = pitch[0].toFixed(1)
            const clarity = (pitch[1] * 100).toFixed(1)
            this.outputTarget.textContent = `Frequency: ${frequency} Hz (Clarity: ${clarity}%)`
        }
    }

    disconnect() {
        if (this.audioContext) {
            this.processor?.disconnect()
            this.source?.disconnect()
            this.audioContext.close()
        }
        if (this.detector) {
            this.detector.free()
        }
    }
}