import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["editor"]
    static values = {
        url: String,
        delay: { type: Number, default: 200 }
    }

    connect() {
        this.saveContent = this.debounce(this.saveContent.bind(this), this.delayValue)

        this.element.addEventListener("trix-change", () => this.saveContent())
    }

    disconnect() {
        this.element.removeEventListener("trix-change", () => this.saveContent())
    }

    saveContent() {
        const data = new FormData(this.element)

        fetch(this.urlValue, {
            method: 'PATCH',
            body: data,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'text/vnd.turbo-stream.html'
            },
            credentials: 'same-origin'
        })
            .then(response => response.text())
            .then(html => Turbo.renderStreamMessage(html))
            .catch(error => console.error('Error:', error))
    }

    // Simple debounce implementation without lodash dependency
    debounce(func, wait) {
        let timeout
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout)
                func(...args)
            }
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
        }
    }
}