// javascript/controllers/guitar_tools/tuning_selector_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button"]

    connect() {
        console.log("tuning_selector_controller Connected")
        // Set default tuning if none exists
        if (!localStorage.getItem('guitarTools.selectedTuning')) {
            localStorage.setItem('guitarTools.selectedTuning', 'standard')
        }

        this.updateActiveButton()
    }

    selectTuning(event) {
        const selectedTuning = event.currentTarget.dataset.tuning
        localStorage.setItem('guitarTools.selectedTuning', selectedTuning)

        // Update button states
        this.updateActiveButton()

        // Dispatch custom event for other controllers to react to
        const strings = event.currentTarget.dataset.strings.split(',')
        this.dispatch("tuningChanged", { detail: { tuning: selectedTuning, strings: strings } })
    }

    updateActiveButton() {
        const currentTuning = localStorage.getItem('guitarTools.selectedTuning')

        this.buttonTargets.forEach(button => {
            button.dataset.state = button.dataset.tuning === currentTuning ? "active" : "inactive"
        })
    }
}