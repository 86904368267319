// app/javascript/controllers/images/zoomist_controller.js
import BaseController from "../utils/base_controller"
import Zoomist from 'zoomist'

export default class extends BaseController {
    static targets = ["container"]

    connect() {
        super.connect()
        this.initZoomist()
    }

    initZoomist(retries = 3) {
        const images = this.containerTarget.querySelectorAll('img')
        if (!images.length) {
            return
        }

        // Track loading state for all images
        let loadedImages = 0
        const totalImages = images.length

        const onImageLoad = () => {
            loadedImages++
            if (loadedImages === totalImages) {
                this.createZoomistInstance()
            }
        }

        // Check if all images are already loaded
        const allImagesLoaded = Array.from(images).every(img =>
            img.complete && img.naturalWidth > 0
        )

        if (allImagesLoaded) {
            this.createZoomistInstance()
        } else if (retries > 0) {
            // Add load listeners for incomplete images
            images.forEach(img => {
                if (img.complete && img.naturalWidth > 0) {
                    onImageLoad()
                } else {
                    img.addEventListener('load', onImageLoad)
                }
            })

            // Retry mechanism for images that fail to load
            setTimeout(() => {
                if (loadedImages < totalImages && retries > 0) {
                    console.log(`Retrying Zoomist initialization. Attempts remaining: ${retries - 1}`)
                    this.initZoomist(retries - 1)
                }
            }, 1000)
        } else {
            console.log("Failed to initialize Zoomist after all retries")
        }
    }

    createZoomistInstance() {
        if (this.zoomist) {
            this.zoomist.destroy()
        }

        this.zoomist = new Zoomist(this.containerTarget, {
            maxScale: 2,
            minScale: 0.7,
            initScale: 1.0,
            slider: true,
            pinchable: true,
            bounds: false,
            draggable: true,
            wheelable: true
        })
    }

    disconnect() {
        if (this.zoomist) {
            this.zoomist.destroy()
        }
    }
}