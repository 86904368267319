import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["fileContainer", "input", "audioPlayer", "editMode"]
    static values = {
        removeUrl: String,
        updateUrl: String,
        fileType: String
    }

    handleUpload(event) {
        const file = event.target.files[0]
        if (!file) return

        // Start the pulsing effect
        this.startPulsingBorder()

        const formData = new FormData()
        formData.append('sound_file', file)
        formData.append('file_type', this.fileTypeValue)

        fetch( this.updateUrlValue,
            {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
                'Accept': 'text/vnd.turbo-stream.html'
            },
            body: formData,
            credentials: 'same-origin'
        }).then(response => {
            return response.text()
        }).then(html => {
            if (html) {
                Turbo.renderStreamMessage(html)
            }
            this.inputTarget.value = ''
            this.stopPulsingBorder()
        }).catch(error => {
            console.error('Error:', error)
            this.stopPulsingBorder()
        })
    }

    startPulsingBorder() {
        this.editModeTarget.classList.add('pulsing-border-upload')
    }

    stopPulsingBorder() {
        this.editModeTarget.classList.remove('pulsing-border-upload')
    }

    removeFile(event) {
        event.preventDefault()

        fetch(this.removeUrlValue, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({ file_type: this.fileTypeValue })
        }).then(response => {
            if (response.ok) {
                return response.text()
            }
            throw new Error('Remove failed')
        }).then(html => {
            if (html) {
                Turbo.renderStreamMessage(html)
            }
        }).catch(error => {
            console.error('Error:', error)
        })
    }
}