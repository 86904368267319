import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = [
        "container",
        "notes",
        "video",
        "metronome",
        "timer",
        "notesButton",
        "videoButton",
        "metronomeButton",
        "timerButton",
        "sideBar",
        "image"
    ]

    connect() {
        super.connect()
        this.hideAll()
        this.updateButtonStates(null)
    }

    toggleNotes(event) {
        event.preventDefault()
        this.toggleSection("notes")
    }

    toggleVideo(event) {
        event.preventDefault()
        this.toggleSection("video")
    }

    toggleMetronome(event) {
        event.preventDefault()
        this.toggleSection("metronome")
    }

    toggleTimer(event) {
        event.preventDefault()
        this.toggleSection("timer")
    }

    toggleSection(sectionName) {
        if (!this.hasContainerTarget) {
            this.log(`Cannot toggle ${sectionName} section: container not found`, "warn")
            return
        }

        const isVisible = this.containerTarget.classList.contains(`${sectionName}-visible`)
        this.hideAll()
        this.updateButtonStates(null)

        if (!isVisible) {
            this.containerTarget.classList.add(`${sectionName}-visible`)
            this.updateButtonStates(sectionName)
        }

        this.updateContainerVisibility()
    }

    hideAll() {
        if (!this.hasContainerTarget) return
        this.containerTarget.classList.remove(
            "notes-visible",
            "video-visible",
            "metronome-visible",
            "timer-visible"
        )
    }

    updateButtonStates(activeView) {
        const buttons = {
            notes: this.notesButtonTarget,
            video: this.videoButtonTarget,
            metronome: this.metronomeButtonTarget,
            timer: this.timerButtonTarget
        }

        Object.entries(buttons).forEach(([view, button]) => {
            button.dataset.state = view === activeView ? "active" : "inactive"
        })
    }

    updateContainerVisibility() {
        if (!this.hasContainerTarget) return

        const hasVisibleContent = ["notes", "video", "metronome", "timer"].some(section =>
            this.containerTarget.classList.contains(`${section}-visible`)
        )

        this.containerTarget.classList.toggle("no-content-visible", !hasVisibleContent)
    }
}