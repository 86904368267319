// search_type_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["all", "scales", "arpeggios", "allButton", "scalesButton", "arpeggiosButton"]

    connect() {
        // Load saved preference from localStorage or default to 'all'
        const savedType = localStorage.getItem('searchType') || 'all'
        this.updateView(savedType)
    }

    toggleAll() {
        this.updateView('all')
    }

    toggleScales() {
        this.updateView('scales')
    }

    toggleArpeggios() {
        this.updateView('arpeggios')
    }

    updateView(type) {
        localStorage.setItem('searchType', type)

        // Hide all views
        this.allTarget.classList.add('hidden')
        this.scalesTarget.classList.add('hidden')
        this.arpeggiosTarget.classList.add('hidden')

        // Show selected view
        switch(type) {
            case 'all':
                this.allTarget.classList.remove('hidden')
                break
            case 'scales':
                this.scalesTarget.classList.remove('hidden')
                break
            case 'arpeggios':
                this.arpeggiosTarget.classList.remove('hidden')
                break
        }

        // Update button states
        const buttons = {
            all: this.allButtonTarget,
            scales: this.scalesButtonTarget,
            arpeggios: this.arpeggiosButtonTarget
        }

        Object.entries(buttons).forEach(([buttonType, button]) => {
            button.dataset.state = buttonType === type ? 'active' : 'inactive'
        })
    }
}