import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["atomType", "reportingPeriod", "specificDate"]

    connect() {
        this.updateReportingPeriod()
    }

    updateReportingPeriod() {
        const selectedDate = this.specificDateTarget.value
        const reportingPeriodSelect = this.reportingPeriodTarget
        const dateOption = Array.from(reportingPeriodSelect.options).find(option => option.value === 'specific_date')

        if (selectedDate) {
            if (!dateOption) {
                const newOption = new Option(selectedDate, 'specific_date')
                reportingPeriodSelect.add(newOption)
            } else {
                dateOption.text = selectedDate
            }
            reportingPeriodSelect.value = 'specific_date'
        } else if (dateOption && reportingPeriodSelect.value !== 'specific_date') {
            reportingPeriodSelect.removeChild(dateOption)
        }
    }

    handleDateChange() {
        this.updateReportingPeriod()
        this.submitForm()
    }

    handleReportingPeriodChange() {
        if (this.reportingPeriodTarget.value !== 'specific_date') {
            this.specificDateTarget.value = ''
            this.updateReportingPeriod()
        }
        this.submitForm()
    }

    submitForm() {
        this.element.requestSubmit()
    }
}