import { Controller } from "@hotwired/stimulus"
import { createLogger } from "./logging"

export default class BaseController extends Controller {
    static values = {
        debug: { type: Boolean, default: false }
    }

    connect() {
        this.log = createLogger(this)
        this.log(`${this.identifier} controller connected`, "info")
    }

    disconnect() {
        this.log(`${this.identifier} controller disconnected`, "info")
    }
}