// app/javascript/controllers/cookie_consent_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["banner"]

    connect() {
        if (!this.hasConsent()) {
            this.bannerTarget.classList.remove("hidden")
        }
    }

    accept() {
        this.setConsent("accepted")
        this.hideBanner()
    }

    reject() {
        this.setConsent("rejected")
        this.hideBanner()
    }

    hideBanner() {
        this.bannerTarget.classList.add("hidden")
    }

    hasConsent() {
        return this.getCookie("cookie_consent") || localStorage.getItem("cookie_consent")
    }

    setConsent(value) {
        this.setCookie("cookie_consent", value, 365)
        localStorage.setItem("cookie_consent", value)
    }

    getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    setCookie(name, value, days) {
        const date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;SameSite=Lax;Secure`
    }
}