import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = [
        "container",
        "notes",
        "video",
        "sound",
        "notesButton",
        "videoButton",
        "soundButton"
    ]

    connect() {
        super.connect()
        this.initializeController()
    }

    disconnect() {
        super.disconnect()
    }

    initializeController() {
        if (this.hasContainerTarget) {
            this.hideAll()
            this.updateButtonStates(null)
            this.updateContainerVisibility()
        } else {
            this.log("Container target not found. Some functionality may be limited.", "warn")
        }
    }

    toggleSound(event) {
        event.preventDefault()
        this.toggleSection("sound")
    }

    toggleNotes(event) {
        event.preventDefault()
        this.toggleSection("notes")
    }

    toggleVideo(event) {
        event.preventDefault()
        this.toggleSection("video")
    }

    toggleSection(sectionName) {
        if (!this.hasContainerTarget) {
            this.log(`Cannot toggle ${sectionName} section: container not found`, "warn")
            return
        }

        const isVisible = this.containerTarget.classList.contains(`${sectionName}-visible`)
        this.hideAll()
        this.updateButtonStates(null)

        if (!isVisible) {
            this.containerTarget.classList.add(`${sectionName}-visible`)
            this.updateButtonStates(sectionName)
        }

        this.updateContainerVisibility()
    }

    hideAll() {
        if (!this.hasContainerTarget) return
        this.containerTarget.classList.remove("notes-visible", "video-visible", "sound-visible")
    }

    updateButtonStates(activeView) {
        const buttons = {
            sound: this.soundButtonTarget,
            notes: this.notesButtonTarget,
            video: this.videoButtonTarget
        }

        Object.entries(buttons).forEach(([view, button]) => {
            button.dataset.state = view === activeView ? "active" : "inactive"
        })
    }

    updateContainerVisibility() {
        if (!this.hasContainerTarget) return

        const hasVisibleContent = ["notes", "video", "sound"].some(section =>
            this.containerTarget.classList.contains(`${section}-visible`)
        )

        this.containerTarget.classList.toggle("no-content-visible", !hasVisibleContent)
    }
}