import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["tuning","button"]
    static values = {
        currentlyPlaying: { type: String, default: '' }
    }

    initialize() {
        super.initialize()
        this.audio = new Audio()
        // Reset all buttons initially
        this.resetAllButtons()
    }

    disconnect() {
        this.stopPlaying()
        super.disconnect()
    }

    play(event) {
        const button = event.currentTarget
        const tuning = button.dataset.tuning
        const sound = button.dataset.sound

        // If this button is currently playing, stop it
        if (this.currentlyPlayingValue === sound) {
            this.stopPlaying()
            return
        }

        // Make sure to reset all buttons first
        this.resetAllButtons()

        // Then start the new sound
        this.startPlaying(sound, tuning, button)
    }

    startPlaying(sound, tuning, button) {
        this.audio.src = `/sounds/tuner/${tuning}/${sound}.mp3`
        this.currentlyPlayingValue = sound

        // Set only this button to playing
        button.dataset.playing = 'true'

        this.audio.play().catch(error => {
            console.error("Error playing sound:", error)
            this.stopPlaying()
        })

        // Add ended event listener
        this.audio.addEventListener('ended', () => this.stopPlaying(), { once: true })
    }

    stopPlaying() {
        if (this.audio) {
            this.audio.pause()
            this.audio.currentTime = 0
        }

        this.resetAllButtons()
        this.currentlyPlayingValue = ''
    }

    resetAllButtons() {
        // Explicitly reset all buttons to false
        this.buttonTargets.forEach(btn => {
            btn.dataset.playing = 'false'
        })
    }
}