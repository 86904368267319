import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    static targets = ["imageGrid", "uploadInput", "editMode"]
    static values = {
        updateUrl: String,
        removeUrl: String,
        addUrl: String
    }

    connect() {
        this.initializeSortable()
    }

    disconnect() {
        if (this.sortable) {
            this.sortable.destroy()
        }
    }

    initializeSortable() {
        if (this.sortable) {
            this.sortable.destroy()
        }

        this.sortable = Sortable.create(this.imageGridTarget, {
            animation: 150,
            onEnd: this.updateOrder.bind(this),
            draggable: "[data-image-id]",
            handle: "[data-image-id]"
        })
    }

    handleUpload(event) {
        const file = event.target.files[0]
        if (!file) return

        this.startPulsingBorder()

        const formData = new FormData()
        formData.append('image', file)

        fetch(this.addUrlValue, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
            },
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.text()
            }
            throw new Error('Upload failed')
        }).then(html => {
            if (html) {
                Turbo.renderStreamMessage(html)
                requestAnimationFrame(() => {
                    this.initializeSortable()
                })
            }
            event.target.value = ''
            this.stopPulsingBorder()
        }).catch(error => {
            console.error('Error:', error)
            this.stopPulsingBorder()
        })
    }

    updateOrder(event) {
        const imageIds = Array.from(this.imageGridTarget.children).map(el => el.dataset.imageId)

        fetch(this.updateUrlValue, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({image_order: imageIds})
        }).then(response => {
            if (response.ok) {
                return response.text()
            }
        }).then(html => {
            if (html) {
                Turbo.renderStreamMessage(html)
                requestAnimationFrame(() => {
                    this.initializeSortable()
                })
            }
        })
    }

    removeImage(event) {
        const imageDiv = event.currentTarget.closest('[data-image-id]')
        if (!imageDiv) return

        const imageId = imageDiv.dataset.imageId

        fetch(`${this.removeUrlValue}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({image_id: imageId})
        }).then(response => {
            if (response.ok) {
                return response.text()
            }
        }).then(html => {
            if (html) {
                Turbo.renderStreamMessage(html)
                requestAnimationFrame(() => {
                    this.initializeSortable()
                })
            }
        })
    }

    startPulsingBorder() {
        this.editModeTarget.classList.add('pulsing-border-upload')
    }

    stopPulsingBorder() {
        this.editModeTarget.classList.remove('pulsing-border-upload')
    }
}