// app/javascript/controllers/single_image_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["imageGrid", "input", "editMode"]
    static values = {
        removeUrl: String,
        updateUrl: String,
        imageType: String
    }

    handleUpload(event) {
        const file = event.target.files[0]
        if (!file) return

        this.startPulsingBorder()

        const formData = new FormData()
        formData.append('image', file)
        formData.append('image_type', this.imageTypeValue)

        fetch(this.updateUrlValue, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
                'Accept': 'text/vnd.turbo-stream.html'
            },
            body: formData,
            credentials: 'same-origin'  // Add this to ensure proper handling
        }).then(response => {
            return response.text()  // Need to read the response for Turbo to process it
        }).then(html => {
            if (html) {
                Turbo.renderStreamMessage(html) // Explicitly tell Turbo to process it
            }
            this.inputTarget.value = ''
            this.stopPulsingBorder()
        }).catch(error => {
            console.error('Error:', error)
            this.stopPulsingBorder()
        })
    }

    startPulsingBorder() {
        this.editModeTarget.classList.add('pulsing-border-upload')
    }

    stopPulsingBorder() {
        this.editModeTarget.classList.remove('pulsing-border-upload')
    }


    removeImage(event) {
        event.preventDefault()

        fetch(this.removeUrlValue, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({ image_type: this.imageTypeValue })
        }).then(response => {
            if (response.ok) {
                return response.text()
            }
            throw new Error('Remove failed')
        }).then(html => {
            if (html) {
                Turbo.renderStreamMessage(html)
            }
        }).catch(error => {
            console.error('Error:', error)
        })
    }
}