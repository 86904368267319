import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "display",
        "startButton",
        "resetButton",
        "stopwatchBtn",
        "countdownBtn",
        "intervalsBtn",
        "totalTime",
        "sessionCount",
        "presetButtons",
        "intervalSettings"
    ]

    static values = {
        mode: { type: String, default: "stopwatch" },
        isRunning: { type: Boolean, default: false },
        startTime: Number,
        elapsedTime: { type: Number, default: 0 },
        countdownTime: { type: Number, default: 0 },
        intervalWork: { type: Number, default: 1500 },
        intervalRest: { type: Number, default: 300 },
        todaysPractice: { type: Number, default: 0 },
        sessionCount: { type: Number, default: 0 },
        atomId: { type: String, default: "global" }
    }

    connect() {
        // Initialize single AudioContext instance
        this.audioContext = null
        this.initAudioContext()

        this.cleanupOldData()
        this.loadSavedSettings()
        this.init()
        this.loadPracticeData()
        this.updateUIForMode()
    }

    disconnect() {
        // Save final practice time if timer was running
        if (this.isRunningValue && this.practiceStartTime) {
            const practiceTime = Math.floor((Date.now() - this.practiceStartTime) / 1000)
            this.updatePracticeTime(practiceTime)
            this.practiceStartTime = null
        }

        // Update running state
        this.isRunningValue = false
    }

    // Add new method to initialize AudioContext
    initAudioContext() {
        // Create audio context on first user interaction
        const initializeAudio = () => {
            if (!this.audioContext) {
                this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
            }
            // Remove event listeners after first interaction
            document.removeEventListener('touchstart', initializeAudio)
            document.removeEventListener('click', initializeAudio)
        }

        document.addEventListener('touchstart', initializeAudio)
        document.addEventListener('click', initializeAudio)
    }

    init() {
        this.timer = null
        this.intervalTimer = null
        this.updateDisplay(0)
        this.updateModeButtons()
    }

    // Storage key methods
    getStatsStorageKey() {
        const today = new Date().toDateString()
        const baseKey = this.atomIdValue === "global" ? "practiceStats" : `atom_${this.atomIdValue}_practiceStats`
        return `${baseKey}_${today}`
    }

    getSettingsStorageKey() {
        const baseKey = this.atomIdValue === "global" ? "timerSettings" : `atom_${this.atomIdValue}_timerSettings`
        return baseKey
    }

    cleanupOldData() {
        const today = new Date().toDateString()
        const keysToRemove = []

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i)
            if (key.includes('practiceStats_')) {
                const keyDate = key.split('_').pop()
                if (keyDate !== today) {
                    keysToRemove.push(key)
                }
            }
        }

        keysToRemove.forEach(key => localStorage.removeItem(key))
    }

    // Settings management
    loadSavedSettings() {
        const savedSettings = JSON.parse(localStorage.getItem(this.getSettingsStorageKey()) || '{}')

        if (savedSettings.timerMode) {
            this.modeValue = savedSettings.timerMode
        }

        if (savedSettings.intervalWork && savedSettings.intervalRest) {
            this.intervalWorkValue = savedSettings.intervalWork
            this.intervalRestValue = savedSettings.intervalRest

            if (this.hasIntervalSettingsTarget) {
                const select = this.intervalSettingsTarget.querySelector('select')
                if (select) {
                    const value = `${savedSettings.intervalWork},${savedSettings.intervalRest}`
                    const option = Array.from(select.options).find(opt => opt.value === value)
                    if (option) {
                        select.value = value
                    }
                }
            }
        }
    }

    saveSettings() {
        const settings = {
            timerMode: this.modeValue,
            intervalWork: this.intervalWorkValue,
            intervalRest: this.intervalRestValue
        }
        localStorage.setItem(this.getSettingsStorageKey(), JSON.stringify(settings))
    }

    // Practice data management
    loadPracticeData() {
        const savedStats = JSON.parse(localStorage.getItem(this.getStatsStorageKey()) || '{}')

        this.todaysPracticeValue = savedStats.totalTime || 0
        this.sessionCountValue = savedStats.sessions || 0

        this.updatePracticeDisplay()
    }

    updatePracticeTime(seconds) {
        this.todaysPracticeValue += seconds
        this.sessionCountValue++

        const stats = {
            totalTime: this.todaysPracticeValue,
            sessions: this.sessionCountValue
        }
        localStorage.setItem(this.getStatsStorageKey(), JSON.stringify(stats))

        this.updatePracticeDisplay()
    }

    // Mode management
    switchMode(event) {
        const newMode = event.currentTarget.dataset.mode
        if (this.isRunningValue) this.stop()
        this.modeValue = newMode
        this.updateModeButtons()
        this.updateUIForMode()
        this.saveSettings()
    }

    updateModeButtons() {
        [this.stopwatchBtnTarget, this.countdownBtnTarget, this.intervalsBtnTarget].forEach(btn => {
            const isActive = btn.dataset.mode === this.modeValue
            btn.dataset.state = isActive ? 'active' : 'inactive'
        })
    }

    updateUIForMode() {
        const isCountdown = this.modeValue === "countdown"
        const isIntervals = this.modeValue === "intervals"

        if (this.hasPresetButtonsTarget) {
            this.presetButtonsTarget.classList.toggle('hidden', !isCountdown)
        }

        if (this.hasIntervalSettingsTarget) {
            this.intervalSettingsTarget.classList.toggle('hidden', !isIntervals)
        }

        this.reset()
    }

    // Timer controls
    start() {
        if (this.isRunningValue) {
            this.stop()
            return
        }

        this.isRunningValue = true

        if (this.modeValue === "countdown") {
            if (this.elapsedTimeValue === 0) return
            this.startTimeValue = Date.now()
        } else {
            this.startTimeValue = Date.now() - this.elapsedTimeValue
        }

        this.updateStartButton()

        if (this.modeValue === "intervals") {
            this.startInterval()
        } else {
            this.timer = setInterval(() => {
                if (this.modeValue === "countdown") {
                    const timeElapsed = Date.now() - this.startTimeValue
                    const remaining = Math.max(this.elapsedTimeValue - timeElapsed, 0)
                    this.updateDisplay(remaining)
                    if (remaining === 0) {
                        this.stop()
                        this.notify("Time's up!")
                        this.playAlertSound()
                    }
                } else {
                    this.elapsedTimeValue = Date.now() - this.startTimeValue
                    this.updateDisplay(this.elapsedTimeValue)
                }
            }, 10)
        }

        this.practiceStartTime = Date.now()
    }

    stop() {
        if (!this.isRunningValue) return

        this.isRunningValue = false
        clearInterval(this.timer)
        clearInterval(this.intervalTimer)
        this.timer = null
        this.intervalTimer = null
        this.updateStartButton()

        if (this.practiceStartTime) {
            const practiceTime = Math.floor((Date.now() - this.practiceStartTime) / 1000)
            this.updatePracticeTime(practiceTime)
            this.practiceStartTime = null
        }
    }

    reset() {
        this.stop()
        this.elapsedTimeValue = 0
        this.countdownTimeValue = 0
        this.updateDisplay(0)
    }

    // Countdown presets
    setPresetTime(event) {
        if (this.modeValue !== "countdown") return

        if (this.isRunningValue) {
            this.stop()
        }

        const minutes = parseInt(event.currentTarget.dataset.minutes)
        const milliseconds = minutes * 60 * 1000
        this.countdownTimeValue = milliseconds
        this.elapsedTimeValue = milliseconds
        this.updateDisplay(milliseconds)
    }

    // Interval management
    updateIntervalSettings(event) {
        const [workTime, restTime] = event.currentTarget.value.split(',').map(Number)
        this.intervalWorkValue = workTime
        this.intervalRestValue = restTime

        if (this.modeValue === "intervals" && this.isRunningValue) {
            this.stop()
            this.start()
        }

        this.saveSettings()
    }

    startInterval() {
        this.isInWorkPeriod = true
        this.remainingTime = this.intervalWorkValue

        this.intervalTimer = setInterval(() => {
            this.remainingTime--
            this.updateDisplay(this.remainingTime * 1000)

            if (this.remainingTime <= 0) {
                this.toggleInterval()
                this.playAlertSound()
                this.notify(`${this.isInWorkPeriod ? 'Work' : 'Rest'} period started`)
            }
        }, 1000)

        this.playAlertSound()
        this.notify("Work period started")
    }

    toggleInterval() {
        this.isInWorkPeriod = !this.isInWorkPeriod
        this.remainingTime = this.isInWorkPeriod ? this.intervalWorkValue : this.intervalRestValue

        this.playAlertSound()
        this.notify(`${this.isInWorkPeriod ? 'Work' : 'Rest'} period started`)
    }

    // UI updates
    updateDisplay(timeInMs) {
        const hours = Math.floor(timeInMs / 3600000)
        const minutes = Math.floor((timeInMs % 3600000) / 60000)
        const seconds = Math.floor((timeInMs % 60000) / 1000)

        this.displayTarget.textContent =
            `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    updateStartButton() {
        const img = this.startButtonTarget.querySelector('img')
        img.src = this.isRunningValue ?
            this.startButtonTarget.dataset.pauseSrc :
            this.startButtonTarget.dataset.playSrc
    }

    updatePracticeDisplay() {
        const hours = Math.floor(this.todaysPracticeValue / 3600)
        const minutes = Math.floor((this.todaysPracticeValue % 3600) / 60)
        this.totalTimeTarget.textContent = `Total: ${hours}h ${minutes}m`
        this.sessionCountTarget.textContent = `Sessions: ${this.sessionCountValue}`
    }

    // Sound and notifications
    playAlertSound() {
        // Ensure we have an audio context
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
        }

        // Resume audio context if it was suspended (common on mobile)
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume()
        }

        const oscillator = this.audioContext.createOscillator()
        const gainNode = this.audioContext.createGain()

        oscillator.connect(gainNode)
        gainNode.connect(this.audioContext.destination)

        oscillator.frequency.value = this.isInWorkPeriod ? 880 : 440
        gainNode.gain.value = 0.5

        const now = this.audioContext.currentTime
        oscillator.start(now)
        oscillator.stop(now + 0.2)

        gainNode.gain.exponentialRampToValueAtTime(0.01, now + 0.2)

        // Clean up nodes after they're done
        setTimeout(() => {
            oscillator.disconnect()
            gainNode.disconnect()
        }, 300)
    }

    notify(message) {
        if (Notification.permission === "granted") {
            new Notification("Interval Timer", {
                body: message,
                silent: true
            })
        }
    }
}